<template>
  <app-dialog
    v-model="model"
    :scrollable="false"
    :submitting="submitting"
    :subtitle="content.subtitle"
    :title="content.title"
    :width="900"
    @submit="onSubmit"
  >
    <div>
      <registration-request-member-select
        v-model="members"
        :exclude="alreadyRegisteredMemberIds"
        :max="session.remaining_seats!"
        @close="memberSelectionOnGoing = false"
        @open="memberSelectionOnGoing = true"
      />

      <transition-group
        v-if="members.length > 0"
        class="mt-4 flex flex-wrap gap-2"
        enter-active-class="transition"
        enter-from-class="opacity-0 translate-y-1"
        leave-active-class="transition"
        leave-to-class="opacity-0 translate-y-1"
        move-class="transition-all"
        tag="div"
      >
        <organization-member-chip
          v-for="member in members"
          :key="member.id"
          deletable
          :member="member"
          @delete="unselectMember(member)"
        />
      </transition-group>
    </div>

    <section v-if="alreadyRegisteredMemberRequests?.length" class="mt-6">
      <h3 class="mb-3 text-sm font-semibold">
        {{ $t("registration_request.dialog.already_registered_members_label") }}
      </h3>

      <div class="flex flex-wrap gap-2">
        <organization-member-chip
          v-for="request in alreadyRegisteredMemberRequests"
          :key="request.id"
          class="!bg-gray-50 !ring-gray-100"
          :member="request.member"
        />
      </div>
    </section>

    <section v-if="hasRole('MEMBER')" class="mt-8">
      <h3 class="mb-3 text-sm font-semibold">
        {{ $t("registration_request.dialog.as_member.comment_label") }}
      </h3>

      <app-form-input-textarea
        v-model="comment"
        data-cy-comment
        hide-label
        :label="$t('registration_request.dialog.as_member.comment_label')"
        :placeholder="
          $t('registration_request.dialog.as_member.comment_placeholder')
        "
        required
      />
    </section>

    <template #footer>
      <app-button
        block
        :disabled="members.length === 0 || memberSelectionOnGoing"
        size="lg"
        type="submit"
      >
        {{ content.cta }}
      </app-button>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "@pollen/core/model/i18n.model";

import type { CoursePageView } from "~/business-areas/course/course.model";
import type { CourseSessionView } from "~/business-areas/course-session/course-session.model";
import { useRegistrationRequestCreationHook } from "~/business-areas/registration-request/composables/registration-request-creation.hook";
import { useRegistrationRequestList } from "~/business-areas/registration-request/composables/registration-request-list.hook";
import { toAnalyticsCourseView } from "~~/model/analytics.model";
import type {
  OrganizationMemberView,
  RegistrationRequestView,
} from "~~/model/organization.model";

const properties = defineProps<{
  modelValue: boolean;
  course: CoursePageView;
  session: CourseSessionView;
}>();

const emit = defineEmits<{
  submit: [requests: RegistrationRequestView[]];
}>();

const { profile, hasRole } = useOrganizationMemberProfile();
const model = useVModel(properties, "modelValue");
const memberSelectionOnGoing = ref(false);

const {
  list: alreadyRegisteredMemberRequests,
  memberIds: alreadyRegisteredMemberIds,
} = await useRegistrationRequestList({
  session_id: properties.session.id,
  status: ["ACCEPTED", "PENDING"],
});

const { create, submitting } = useRegistrationRequestCreationHook({
  session_id: properties.session.id,
});

const members = ref<OrganizationMemberView[]>(
  profile.value && !alreadyRegisteredMemberIds.value.includes(profile.value.id)
    ? [profile.value as unknown as OrganizationMemberView]
    : [],
);
const comment = ref("");

const { t } = useI18n();
const content = computed<{
  title: I18nMessage;
  subtitle: I18nMessage;
  cta: I18nMessage;
}>(() => {
  if (hasRole("OWNER")) {
    const cta =
      members.value.length > 1
        ? t("registration_request.dialog.as_owner.cta", {
            count: members.value.length,
          })
        : t("registration_request.cta.as_owner");

    return {
      cta,
      title: t("registration_request.dialog.as_owner.title"),
      subtitle: t("registration_request.dialog.as_owner.subtitle"),
    };
  }

  return {
    cta: t("app.cta.send"),
    title: t("registration_request.dialog.as_member.title"),
    subtitle: t("registration_request.dialog.as_member.subtitle"),
  };
});

function unselectMember(member: OrganizationMemberView) {
  members.value = members.value.filter((m) => m.id !== member.id);
}

const { $analytics } = useNuxtApp();
function onSubmit() {
  return create(
    { members: members.value, comment: comment.value },
    {
      async onSuccess(payload) {
        payload.forEach(() => {
          $analytics.track("registration_request_created", {
            course: toAnalyticsCourseView(properties.course),
          });
        });

        emit("submit", payload);
        model.value = false;
      },
    },
  );
}
</script>
